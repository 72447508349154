import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Row } from '../components/helpers';
import Calendar from '../../src/Icons/Calendar';
import Free from '../../src/Icons/Free';
import Value from '../../src/Icons/Value';
import WarrantyCertificate from '../../src/Icons/WarrantyCertificate';
import DeliveryTruck from '../../src/Icons/DeliveryTruck';
import Mattresses from '../../src/Icons/Mattresses';
import PaperMoney from '../../src/Icons/PaperMoney';
import People from '../../src/Icons/People';
import Zoom from '../../src/Icons/Zoom';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "icons"
    }}>{`Icons`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Icon from 'mfcl/Icons/[Icon Name]'
`}</code></pre>
    <Playground __position={0} __code={'<Row>\n  <Calendar width={30} height={30} />\n  <Free width={30} height={30} />\n  <Value width={30} height={30} />\n  <WarrantyCertificate width={30} height={30} />\n  <DeliveryTruck width={30} height={30} />\n  <Mattresses width={30} height={30} />\n  <PaperMoney width={30} height={30} />\n  <People width={30} height={30} />\n  <Zoom width={30} height={30} />\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Row,
      Calendar,
      Free,
      Value,
      WarrantyCertificate,
      DeliveryTruck,
      Mattresses,
      PaperMoney,
      People,
      Zoom,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <Calendar width={30} height={30} mdxType="Calendar" />
    <Free width={30} height={30} mdxType="Free" />
    <Value width={30} height={30} mdxType="Value" />
    <WarrantyCertificate width={30} height={30} mdxType="WarrantyCertificate" />
    <DeliveryTruck width={30} height={30} mdxType="DeliveryTruck" />
    <Mattresses width={30} height={30} mdxType="Mattresses" />
    <PaperMoney width={30} height={30} mdxType="PaperMoney" />
    <People width={30} height={30} mdxType="People" />
    <Zoom width={30} height={30} mdxType="Zoom" />
  </Row>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      